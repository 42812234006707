import React, {useEffect, useRef, useState} from 'react';
import type {WdiModalAppProps} from '@/components/WdiControls';
import {FieldProps} from "@/components/WdiControls";
import type {ProFormInstance} from '@ant-design/pro-form';
import {EC} from '@/services/mdm/ec';
import {WdiSelect, WdiTreeSelect} from '@/components/WdiControls/WdiSelect';
import {Form, Input, message} from 'antd';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {Enum} from '@/enums/system';
import {DataOptions} from '@/services/basic/metadata';
import {Product} from "@/services/mdm/product";
import {SoldTo} from '@/services/mdm/soldto';
import {FormSalesContract} from "@/services/contract/formsalescontract";

const {TextArea} = Input;

const processModeDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({typeGroup: 13}, params)).then((response) => {
    return response.data.map((item) => ({value: item.value, label: item.name}));
  });
};

const SalesContractTermsNonAutoEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [isInDirect, setIsInDirect] = useState<boolean>(false);

  useEffect(() => {
    formRef.current?.setFieldsValue({
      processMode: props?.data?.row?.processMode,
    });
    if (props.data?.pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT) {
      setIsInDirect(true);
    }
    if (props.data.row) {
      const detail = props.data.row;
      formRef.current?.setFieldsValue({
        salesContractTermsId: detail.salesContractTermsId,
        ecId: detail.ecId,
        termsAmount: detail.termsAmount,
        termsAmountDealer: detail.termsAmountDealer,
        remark: detail.remark,
        termDescribe: detail.termDescribe,
      });

      if (detail.productIdsRebate) {
        Product.searchAll({productIds: detail.productIdsRebate}).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsRebate: response.data.map((product) => ({
                value: product.productId,
                label: product.codeOrName,
              })),
            });
          }
        });
      } else {
        Product.searchAll({productLevel: Enum.ProductLevel.ROOT}).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsRebate: response.data.map((product) => ({
                value: product.productId,
                label: product.productName,
              })),
            });
          }
        })
      }
    }
  }, [props.data.row, props.type]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{span: 6}}
      wrapperCol={{span: 12}}
      labelAlign="left"
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      preserve={true}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null;
        },
        destroyOnClose: true,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const {productIdsRebate, ...entity} = values;
        if (productIdsRebate) {
          entity.productIdsRebate = productIdsRebate.map((item: any) => item.value).join();
        }

        const response = await FormSalesContract.Buttons.validateDetail({
          rowIndex: props.data.rowIndex,
          formSalesContract: {
            pageTypeId: props.data?.pageTypeId,
            soldToCode: props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode,
            revenueForecast: props.data?.revenueForecast
          },
          formSalesContractTerms: entity,
          formSalesContractTermsList: props.data.rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (props.onSave) {
            props.onSave({
              code: ResponseStatus.SUCCESS,
              message: '',
              data: response.data,
            });
          }
        }
      }}
    >
      <Form.Item name="salesContractTermsId" label="salesContractTermsId" hidden>
        <Input/>
      </Form.Item>
      <Form.Item
        name="processMode"
        label="条款类型"
        rules={[{required: true, message: '条款类型不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择条款类型',
            disabled: true,
            dataLoading: processModeDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="ecId"
        label="费用项"
        rules={[{required: true, message: '费用项不能为空!'}]}
      >
        <WdiTreeSelect {...{
          placeholder: "请选择费用项",
          dataLoading: async (params) => {
            const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);
            const response = await EC.searchEc3(Object.assign({
              toParentLevel: Enum.ECLevel.EC1,
              withPermissions: true,
              isActive: true,
              pageTypeIds: [props.data?.pageTypeId].join(),
              processModeId: props?.data?.row?.processMode,
              bohqId: soldto.data.defaultBohqId
            }, params));

            const {treeData, dict} = FieldProps.TreeSelect.formatTreeData({
              textField: "ecName",
              valueField: "ecId",
              id: "ecId",
              pId: "parentId",
              level: "ecLevel",
              disabledLevel: [Enum.ECLevel.EC1, Enum.ECLevel.EC2],
            }, response.data);

            return {treeData, dict};
          }
        }} />
      </Form.Item>
      <Form.Item
        name="productIdsRebate"
        label="返利产品"
        rules={[{required: true, message: '返利产品不能为空!'}]}
      >
        <WdiTreeSelect
          {...{
            multiple: true,
            placeholder: '请选择返利产品',
            dataLoading: async (params) => {
              const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);

              return Product.searchAll(Object.assign({
                productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
                isActive: true,
                bohqId: soldto.data.defaultBohqId
              }, params)).then(
                (response) => {
                  const {treeData, dict} = FieldProps.TreeSelect.formatTreeData(
                    {
                      textField: 'productName',
                      valueField: 'productId',
                      id: 'productId',
                      pId: 'parentId',
                      level: 'productLevel',
                    },
                    response.data,
                  );
                  return {treeData, dict};
                },
              );
            },
            onExSelect: (value: any, node: any, dict: any) => {
              const values = formRef.current?.getFieldValue('productIdsRebate');
              const rtv = FieldProps.TreeSelect.labeledValuesMeger(dict, values, {value});
              formRef.current?.setFieldsValue({
                productIdsRebate: rtv,
              });
            },
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="termsAmount"
        label="固定金额"
        rules={[{required: true, message: '固定金额不能为空!'}]}
      >
        <WdiInputNumber format="d" digits={2} min={0}/>
      </Form.Item>
      <Form.Item
        name="termsAmountDealer"
        label="经销商承担金额"
        hidden={!isInDirect}
        rules={[{required: isInDirect, message: '经销商承担金额不能为空!'}]}
      >
        <WdiInputNumber format="d" digits={2} min={0}/>
      </Form.Item>
      <Form.Item name="termDescribe" label="细项描述">
        <TextArea rows={3} style={{width: '100%'}} placeholder={"请填写对应合同项的详细描述。包括并不限于：执行要求，执行标准，执行次数，执行频率，支付单价等（可参考合同文本内对应描述做填写）"}/>
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <TextArea rows={3} style={{width: '100%'}}/>
      </Form.Item>
    </WdiModalForm>
  );
};

export default SalesContractTermsNonAutoEditApp;
