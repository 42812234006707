import React, {useEffect, useMemo, useRef, useState} from 'react';
import type {WdiModalAppProps} from '@/components/WdiControls';
import {FieldProps} from '@/components/WdiControls';
import type {ProFormInstance} from '@ant-design/pro-form';
import {EC} from '@/services/mdm/ec';
import {WdiSelect, WdiTreeSelect} from '@/components/WdiControls/WdiSelect';
import {Form, Input, message} from 'antd';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {Enum} from '@/enums/system';
import {DataOptions} from '@/services/basic/metadata';
import {Product} from '@/services/mdm/product';
import {RevenueType} from '@/services/mdm/revenuetype';
import {convertToResult, initSalesContractRateGrid, SCTRateGrid} from './rate-grid';
import {ModalAppTypeEnums} from './details';
import {SoldTo} from '@/services/mdm/soldto';
import {FormSalesContract} from "@/services/contract/formsalescontract";

const {TextArea} = Input;

const processModeDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({typeGroup: 13}, params)).then((response) => {
    return response.data.map((item) => ({value: item.value, label: item.name}));
  });
};

const revenueTypeDataLoadingFactory = (pageTypeId: number) => {
  return (params: any) => {
    return RevenueType.search(
      Object.assign(
        {
          revenueTypeIds: pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT ? '5,6' : '3,4,5,6',
        },
        params,
      ),
    ).then((response) => {
      return response.data.map((item) => ({
        value: item.revenueTypeId,
        label: item.revenueTypeName,
      }));
    });
  };
};

const settlementFrequencyDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({typeGroup: 15}, params)).then((response) => {
    return response.data.map((item) => ({value: item.value, label: item.name}));
  });
};

const SalesContractTermsAutoEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [editMode, setEditMode] = useState<boolean>();
  const [isQuantity, setIsQuantity] = useState<boolean>();
  const [isRevenueForecast, setIsRevenueForecast] = useState<boolean>();
  const [isInDirect, setIsInDirect] = useState<boolean>(false);

  const revenueTypeDataLoading = useMemo(() => {
    return revenueTypeDataLoadingFactory(props.data?.pageTypeId);
  }, []);

  const onRevenueTypeChange = (revenueTypeId: number) => {
    if (revenueTypeId == Enum.RevenueType.SELLIN || revenueTypeId == Enum.RevenueType.SELLOUT) {
      setIsQuantity(true);
      formRef.current?.setFieldsValue({revenueForecast: undefined,});
    } else if (revenueTypeId == Enum.RevenueType.SELLIN_REV || revenueTypeId == Enum.RevenueType.SELLOUT_REV) {
      setIsQuantity(false);
      formRef.current?.setFieldsValue({quantityForecast: undefined,});
    } else {
      setIsQuantity(undefined);
      formRef.current?.setFieldsValue({quantityForecast: undefined,});
      formRef.current?.setFieldsValue({revenueForecast: undefined,});
    }
  };

  useEffect(() => {
    formRef.current?.setFieldsValue({
      processMode: props?.data?.row?.processMode,
    });
    if (props.data?.pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT) {
      setIsInDirect(true);
    }
    if (props.data.row) {
      const type = Number(props?.type);
      setEditMode(type == ModalAppTypeEnums.EditApply || type == ModalAppTypeEnums.EditAuto || type == ModalAppTypeEnums.EditImport);

      const detail = props.data.row;
      formRef.current?.setFieldsValue({
        salesContractTermsId: detail.salesContractTermsId,
        ecId: detail.ecId,
        revenueType: detail.revenueType,
        settlementFrequency: detail.settlementFrequency,
        rateForecast: detail.rateForecast,
        quantityForecast: detail.quantityForecast,
        revenueForecast: detail.revenueForecast,
        rateDealer: detail.rateDealer,
        remark: detail.remark,
        termDescribe: detail.termDescribe,
      });

      if (detail.revenueType) {
        onRevenueTypeChange(detail.revenueType);
      }

      if (detail.productIdsReach) {
        Product.searchAll({productIds: detail.productIdsReach}).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsReach: response.data.map((product) => ({
                value: product.productId,
                label: product.productName,
              })),
            });
          }
        });
      } else {
        Product.searchAll({productLevel: Enum.ProductLevel.ROOT}).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsReach: response.data.map((product) => ({
                value: product.productId,
                label: product.productName,
              })),
            });
          }
        })
      }

      if (detail.productIdsRebate) {
        Product.searchAll({productIds: detail.productIdsRebate}).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsRebate: response.data.map((product) => ({
                value: product.productId,
                label: product.productName,
              })),
            });
            if (!(response.data.length == 1 && response.data[0].productLevel == Enum.ProductLevel.ROOT)) {
              setIsRevenueForecast(true);
            }
          }
        });
      } else {
        Product.searchAll({productLevel: Enum.ProductLevel.ROOT}).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsRebate: response.data.map((product) => ({
                value: product.productId,
                label: product.productName,
              })),
            });
          }
        })
      }
    }
    formRef.current?.setFieldsValue({
      termsContent: initSalesContractRateGrid(props.data.row.revenueTypeId, props?.data?.row?.termsContent ? JSON.parse(props?.data?.row?.termsContent) : null),
    });
  }, [props.data.row]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{span: 6}}
      wrapperCol={{span: 17}}
      labelAlign="left"
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      preserve={true}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null;
        },
        destroyOnClose: true,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const {termsContent, productIdsReach, productIdsRebate, ...entity} = values;

        const gridResponse: any = convertToResult(termsContent);
        if (gridResponse.code != ResponseStatus.SUCCESS) {
          message.error(String(gridResponse?.message));
          return;
        }
        entity.termsContent = JSON.stringify(gridResponse.data.data);

        if (productIdsReach) {
          entity.productIdsReach = productIdsReach.map((item: any) => item.value).join();
        }

        if (productIdsRebate) {
          entity.productIdsRebate = productIdsRebate.map((item: any) => item.value).join();
        }

        const response = await FormSalesContract.Buttons.validateDetail({
          rowIndex: props.data.rowIndex,
          formSalesContract: {
            pageTypeId: props.data?.pageTypeId,
            soldToCode: props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode,
            revenueForecast: props.data?.revenueForecast
          },
          formSalesContractTerms: entity,
          formSalesContractTermsList: props.data.rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (props.onSave) {
            props.onSave({
              code: ResponseStatus.SUCCESS,
              message: '',
              data: response.data,
            });
          }
        }
      }}
    >
      <Form.Item name="salesContractTermsId" label="salesContractTermsId" hidden>
        <Input/>
      </Form.Item>
      <Form.Item
        name="processMode"
        label="条款类型"
        rules={[{required: true, message: '条款类型不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择条款类型',
            disabled: true,
            dataLoading: processModeDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="ecId"
        label="费用项"
        rules={[{required: true, message: '费用项不能为空!'}]}
      >
        <WdiTreeSelect {...{
          placeholder: "请选择费用项",
          dataLoading: async (params) => {
            const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);
            const response = await EC.searchEc3(Object.assign({
              toParentLevel: Enum.ECLevel.EC1,
              withPermissions: true,
              isActive: true,
              pageTypeIds: [props.data?.pageTypeId].join(),
              processModeId: props?.data?.row?.processMode,
              bohqId: soldto.data.defaultBohqId
            }, params));

            const {treeData, dict} = FieldProps.TreeSelect.formatTreeData({
              textField: "ecName",
              valueField: "ecId",
              id: "ecId",
              pId: "parentId",
              level: "ecLevel",
              disabledLevel: [Enum.ECLevel.EC1, Enum.ECLevel.EC2],
            }, response.data);

            return {treeData, dict};
          }
        }} />
      </Form.Item>
      <Form.Item
        name="productIdsReach"
        label="达量产品"
        rules={[{required: true, message: '达量产品不能为空!'}]}
      >
        <WdiTreeSelect
          {...{
            multiple: true,
            placeholder: '请选择达量产品',
            dataLoading: async (params) => {
              const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);

              return Product.searchAll(Object.assign({
                productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
                isActive: true,
                bohqId: soldto.data.defaultBohqId
              }, params)).then(
                (response) => {
                  const {treeData, dict} = FieldProps.TreeSelect.formatTreeData(
                    {
                      textField: 'productName',
                      valueField: 'productId',
                      id: 'productId',
                      pId: 'parentId',
                      level: 'productLevel',
                    },
                    response.data,
                  );
                  return {treeData, dict};
                },
              );
            },
            onExSelect: (value: any, node: any, dict: any) => {
              const values = formRef.current?.getFieldValue('productIdsReach');
              const rtv = FieldProps.TreeSelect.labeledValuesMeger(dict, values, {value});
              formRef.current?.setFieldsValue({
                productIdsReach: rtv,
              });
            },
          }}
        />
      </Form.Item>
      <Form.Item
        name="productIdsRebate"
        label="返利产品"
        rules={[{required: true, message: '返利产品不能为空!'}]}
      >
        <WdiTreeSelect
          {...{
            multiple: true,
            placeholder: '请选择返利产品',
            dataLoading: async (params) => {
              const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);
              return Product.searchAll(Object.assign({
                productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
                isActive: true,
                bohqId: soldto.data.defaultBohqId
              }, params)).then(
                (response) => {
                  const {treeData, dict} = FieldProps.TreeSelect.formatTreeData(
                    {
                      textField: 'productName',
                      valueField: 'productId',
                      id: 'productId',
                      pId: 'parentId',
                      level: 'productLevel',
                    },
                    response.data,
                  );
                  return {treeData, dict};
                },
              );
            },
            onExSelect: (value: any, node: any, dict: any) => {
              const values = formRef.current?.getFieldValue('productIdsRebate');
              const rtv = FieldProps.TreeSelect.labeledValuesMeger(dict, values, {value});
              const parents: any = FieldProps.TreeSelect.getParentSet(dict, value);
              if (parents.size > 0) {
                setIsRevenueForecast(true);
              } else {
                setIsRevenueForecast(false);
                formRef.current?.setFieldsValue({rateForecast: undefined,});
              }
              formRef.current?.setFieldsValue({
                productIdsRebate: rtv,
              });
            },
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="revenueType"
        label="核算类型"
        rules={[{required: true, message: '核算类型不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择核算类型',
            dataLoading: revenueTypeDataLoading,
            onChange: onRevenueTypeChange
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="settlementFrequency"
        label="结案频率"
        rules={[{required: true, message: '结案频率不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择结案频率',
            disabled: editMode,
            dataLoading: settlementFrequencyDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="termsContent"
        label="返利坎级(ELN承担)"
        rules={[{required: true, message: '返利坎级(ELN承担)不能为空!'}]}
      >
        <SCTRateGrid isQuantity={isQuantity}/>
      </Form.Item>
      <Form.Item
        name="quantityForecast"
        label="预估销量（听）"
        hidden={!isQuantity}
        rules={[{required: isQuantity, message: '预估销量（听）不能为空!'}]}>
        <WdiInputNumber digits={0} min={0} format={"d"} placeholder={'请填写整个合同期内返利产品的预估销量'}/>
      </Form.Item>
      <Form.Item
        name="revenueForecast"
        label="预估销售额（不含税）"
        hidden={(isQuantity || !isRevenueForecast)}
        rules={[{required: (!isQuantity && isRevenueForecast), message: '预估销售额（不含税）不能为空!'}]}>
        <WdiInputNumber digits={0} min={0} format={"d"} placeholder={'请填写整个合同期内返利产品的预估销售额'}/>
      </Form.Item>
      <Form.Item
        name="rateForecast"
        label="ELN预估承担点数（%）"
        hidden={true}>
        <WdiInputNumber digits={4} min={0} format={"p"}/>
      </Form.Item>
      <Form.Item
        name="rateDealer"
        label="经销商承担点数（%）"
        hidden={!isInDirect}
        rules={[{required: isInDirect, message: '经销商承担点数（%）不能为空!'}]}>
        <WdiInputNumber digits={4} min={0} format={"p"}/>
      </Form.Item>
      <Form.Item name="termDescribe" label="细项描述">
        <TextArea rows={3} style={{width: '100%'}} placeholder={"请填写对应合同项的详细描述。包括并不限于：执行要求，执行标准，执行次数，执行频率，支付单价等（可参考合同文本内对应描述做填写）"}/>
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <TextArea rows={3} style={{width: '100%'}}/>
      </Form.Item>
    </WdiModalForm>
  );
};
export default SalesContractTermsAutoEditApp;
